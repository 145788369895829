<template>
  <div class="manage">
    <el-dialog width="20%" :title="operateType === 'add' ? '新增合同外追加' : '修改合同外追加'" :visible.sync="isShow" :close-on-click-modal="false">
      <additionalcontract-form :additionalcontractForm="additionalcontractForm" ref="additionalcontractForm"></additionalcontract-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('additionalcontractForm')">保 存</el-button>
      </div>
    </el-dialog>
    <div class="manage-header">
      <el-button type="primary" @click="addAdditonalContract">+ 新增</el-button>
      <common-form inline :formLabel="formLabel" :form="searchFrom">
        <el-button type="primary">搜索</el-button>
      </common-form>
    </div>

    <common-table
      :tableData="tableData"
      :config="config"
      :usertype="usertype"
      @getSelectedRows="getSelectedRows"
      @changePage="getList"
      @edit="editAdditionalContract"
      @del="delAdditionalContract"
    ></common-table>
  </div>
</template>

<script>
import CommonForm from '../../components/CommonForm'
import CommonTable from './AdditionalContractTable'
import AdditionalcontractForm from './AdditionalContractForm'
// import Cookie from 'js-cookie'
import utils from '../../assets/js/utils'
export default {
  components: {
    CommonForm,
    CommonTable,
    AdditionalcontractForm
  },
  data() {
    return {
      isShow: false,
      operateType: 'add',
      tableData: [],
      usertype: '',
      user_realname: '',

      config: {
        page: 1,
        total: 30,
        loading: false
      },

      additionalcontractForm: {
        id: '',
        contract: '',
        contract_name: '',
        additional_contract_date: '',
        additional_contract_amount: '',
        added_person: '',
        added_person_time: '',
        additional_contract_material: ''
      },

      searchFrom: {
        keyword: ''
      },
      formLabel: [
        {
          model: 'keyword',
          label: ''
        }
      ]
    }
  },

  methods: {
    // 获取回款明细列表
    getList(name = '') {
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      console.log('usertype:', this.usertype)
      console.log('user_realname:', this.user_realname)
      this.config.loading = true
      const api = '/getAdditionalContract/'
      this.$axios
        .get(api, {
          params: {
            page: this.config.page,
            name
          }
        })
        .then(res => {
          this.tableData = res.data.map(item => {
            item.contract_view = item.contract_id + '\n' + item.contract_name
            item.added = item.added_person + '\n' + item.added_person_time
            return item
          })
          console.log('additionalContracts list:', res.data)
          this.config.total = res.count
          this.config.loading = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //增加合同外追加
    addAdditonalContract() {
      this.additionalcontractForm = {}
      this.operateType = 'add'
      this.isShow = true
    },

    //编辑合同外追加
    editAdditionalContract(row) {
      console.log('row........', row)
      this.operateType = 'edit'
      this.isShow = true
      row.additional_contract_amount = parseInt(row.additional_contract_amount)

      this.additionalcontractForm = row
      console.log('The new row:', this.additionalcontractForm)
    },

    //删除合同外追加
    delAdditionalContract(row) {
      this.$confirm('此操作将永久删除此合同外追加, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteAdditionalContract/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getList()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    getSelectedRows(val) {
      console.log('Selected rows:', val)
    },
    submitForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        if (this.operateType === 'edit') {
          //编辑当前合同外追加
          console.log('The new row 2:', this.additionalcontractForm)
          this.$axios.put('/updateAdditionalContract/', this.additionalcontractForm).then(res => {
            console.log('response:', res.data)
            if (res.data.code === 1000) {
              this.$message({
                type: 'success',
                message: '更新合同外追加成功!',
                duration: 2000
              })
              this.isShow = false
              this.getList()
            } else {
              this.$message({
                type: 'warning',
                message: res.data.msg,
                duration: 2000
              })
            }
          })
        } else {
          //增加合同外追加
          this.additionalcontractForm.added_person = this.user_realname
          this.additionalcontractForm.added_person_time = utils.getDateTime()
          console.log('add additionalcontractForm:', this.additionalcontractForm)
          this.$axios.post('/addAdditionalContract/', this.additionalcontractForm).then(res => {
            console.log('response:', res.data)
            if (res.data.code === 1000) {
              this.$message({
                type: 'success',
                message: '增加合同外追加成功!',
                duration: 2000
              })
              this.isShow = false
              this.getList()
            } else {
              this.$message({
                type: 'warning',
                message: res.data.msg,
                duration: 2000
              })
            }
          })
        }
      } else {
        console.log('校验不通过！')
      }
    }
  },
  created() {
    this.getList()
  }
}
</script>
